import { useEffect,useRef} from "react";
import { createBrowserHistory } from 'history';
import GTMEvents from "./GTMEvents";

export const useTrackScroll = () => {
  const firedThresholdsRef = useRef(new Set());
  const history = createBrowserHistory();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const docHeight = document.documentElement.scrollHeight;
      const winHeight = window.innerHeight;
      const scrollPercent = (scrollTop / (docHeight - winHeight)) * 100;

      // Adjust these thresholds as needed
      const thresholds = [25, 50, 75, 100];

      thresholds.forEach(threshold => {
        if (scrollPercent >= threshold && !firedThresholdsRef.current.has(threshold)) {
          //console.log(`========FireEvent====== ${scrollPercent} ${threshold}`);
          GTMEvents.scrollDepth(threshold)
          firedThresholdsRef.current.add(threshold);
        }
      });
    };

    firedThresholdsRef.current.clear(); // Clear thresholds when dependencies change
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [history]);
};
