import React,{ useState, useRef, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import AudioPlayer, { 
  // RHAP_UI 
} from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import GTMEvents from '../utils/GTMEvents';

export default function Podcast() {
  const [audioListIndex, setAudioListIndex] = useState(null);
  const audioRefs = useRef([]);
  
  const podcasts = [
    {
      id: 1,
      title: 'Baby Elephant Walk',
      artist: 'Henry Manc',
      url: 'https://www2.cs.uic.edu/~i101/SoundFiles/BabyElephantWalk60.wav'
    },
    {
      id: 2,
      title: 'Cantina Band',
      artist: 'John Williams',
      url: 'https://www2.cs.uic.edu/~i101/SoundFiles/CantinaBand3.wav'
    },
    {
      id: 3,
      title: 'Yodel Sound Effect',
      artist: 'Abhishek',
      url: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/123941/Yodel_Sound_Effect.mp3"
    },
    {
      id: 4,
      title: 'Fanfare Sound Effect',
      artist: 'Kanishk',
      url: 'https://www2.cs.uic.edu/~i101/SoundFiles/Fanfare60.wav'
    },
    {
      id: 5,
      title: 'Gettysburg Address',
      artist: 'Abraham Lincoln',
      url: 'https://www2.cs.uic.edu/~i101/SoundFiles/gettysburg10.wav'
    }
  ];

  const handleListen = (index) => {
    const audioRef = audioRefs.current[index];
    const audio = audioRef.audio.current
    const duration = audio.duration;
    const currentTime = audio.currentTime;
    let percentagePlayed = (currentTime / duration) * 100;
    //console.log("=======percentagePlayed======",percentagePlayed,audio.currentTime,audio.duration)
    percentagePlayed = (percentagePlayed >= 99.0) ? 100 : percentagePlayed;

    const milestones = {
      0: 'zeroPercent',
      10: 'tenPercent',
      25: 'twentyFivePercent',
      50: 'fiftyPercent',
      75: 'seventyFivePercent',
      100: 'hundredPercentage'
    };
    //console.log("============Progress=======",percentagePlayed,duration,currentTime)
    for (const [percent, label] of Object.entries(milestones)) {
      if (percentagePlayed >= percent && !audio[label]) {
        audio[label] = true; // Mark this milestone as tracked
        audio.percentage = +percent
        audio.title = podcasts[index].title 
        //console.log("============fires=======",percent, label)
        GTMEvents.podcastProgress(audio)
      }
    }
  };

  

  useEffect(() => {
    if (audioListIndex !== null) {
      audioRefs.current.forEach((audioRef, index) => {
        if (index !== audioListIndex && audioRef && audioRef?.isPlaying()) {
          audioRef.audio.current?.pause()
        }
      });
    }
  }, [audioListIndex]);



  return (
    <Box sx={{ p: 4 }}>
      {podcasts?.map((podcast, index) => {
        return <Grid sx={{ mb: 4, width: { md: '60%' } }} key={index}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Typography variant='h6' fontWeight={600} sx={{ mr: 0.5 }}>{podcast?.id}. {podcast?.title}</Typography>
            <Typography variant='subtitle1' fontWeight={400}>by {podcast?.artist}</Typography>
          </Box>
          <AudioPlayer
            ref={el => audioRefs.current[index] = el}
            title = {podcast?.title}
            key={index}
            autoPlay={false}
            src={podcast.url}
            volume={0.8}
            showSkipControls
            progressUpdateInterval={100}
            onPlay={() => setAudioListIndex(index)}
            onListen={() => handleListen(index)}
            listenInterval={100}
          />
        </Grid>}
      )}
    </Box>
  )
}
