import TagManager from 'react-gtm-module';
import { Cookies } from "react-cookie-consent";
import {USERS_DATA} from '../constants/userConstants';

const GTMEvents = {
    pushToDataLayer: (event, data = {}) => {
        let userEmail =  Cookies.get('username');
        let userOcktaID = USERS_DATA.find(user => user.email === userEmail)?.octa_id;

        console.log("===========userOcktaID========",event,userOcktaID)

        const dataLayerObject = {
        event,
        okta_id: userOcktaID ? userOcktaID : '',
        ...data,
        };
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(function() {
            this.reset();
        })
        // window.dataLayer = window.dataLayer || [];
        // window.dataLayer.push(dataLayerObject);
        //console.log('Data Layer Event:', dataLayerObject);
        TagManager.dataLayer({ dataLayer: dataLayerObject });
        // console.log('Data Layer Event:', dataLayerObject);
        // console.log('window.dataLayer', window.dataLayer);
    },
  
    pageChange: (locationPath,previousLocationPath) => {
        const baseUrl = window.location.origin
        const pageLocation = locationPath ?`${baseUrl}${locationPath}` : '';
        const pageReferrer = previousLocationPath ?`${baseUrl}${previousLocationPath}` : '';
        const eventName = 'virtual_page_view'
        GTMEvents.pushToDataLayer(eventName, {
            page_location: pageLocation,
            page_title: document.title,
            page_referrer: pageReferrer, 
            login_status: 'true', 
            error_status: 'no', 
            error_details: '',
            error_type: '', 
            user_location: '', 
            user_location_status:'', 
            site_section:locationPath.replace(/\//g, "")
        });
    },

    userLogin: (status,usersData={}) => {
        let eventName = ''
        if(status === 'SUCCESS'){
            eventName = 'user_login_success_event'
        }
        GTMEvents.pushToDataLayer(eventName, {});
    },

    logout: (text,action) => {
        let eventName = 'logout'
        GTMEvents.pushToDataLayer(eventName,{
            'link_text': text, 
            'link_url': window.location.href,
            'user_action': action, 
        });
    },

    formSubmit: (e,formName) => {
        let eventName = 'form_submit'
        const formData = new FormData(e.target);
        const formLength = [...formData.entries()].length;

        let eventData = {
            'form_name': formName, 
            'form_destination': e.target?.action,
            'form_id': e.target?.id,
            'form_length': formLength
        }
        //console.log("eventData",eventName,eventData)

        GTMEvents.pushToDataLayer(eventName,eventData );
    },

    formInteraction: (e,formName) => {
        const eventName = 'form_start'
        const form = e.target.form;
        const formData = new FormData(form);
        const formLength = [...formData.entries()].length;
        let eventData = {
            form_name: formName,
            form_destination: form?.action,
            form_id: form?.id,
            form_length: formLength,
            first_field_id: e.target.id,
            first_field_name: e.target.name,
        }
        //console.log("eventData",eventName,eventData)
        GTMEvents.pushToDataLayer(eventName, eventData);
    },

    userCreation: (status) => {
        let eventName = ''
        if(status === 'SUCCESS'){
            eventName = 'user_create_success_event'
        }
        GTMEvents.pushToDataLayer(eventName, {});
    },
    
    userEdited: (status) => {
        let eventName = ''
        if(status === 'SUCCESS'){
            eventName = 'user_edit_success_event'
        }
        GTMEvents.pushToDataLayer(eventName, {});
    },

    videoProgress: (videoElm) => {
        let eventName = ''
        if(videoElm.percentage === 0){
            eventName = 'video_start'
        }else if(videoElm.percentage === 100){
            eventName = 'video_complete'
        }else{
            eventName = 'video_progress'
        }
        //console.log("GTM",videoElm.percentage,eventName)
        GTMEvents.pushToDataLayer(eventName, {
            'video_title': videoElm.videoTitle,
            'video_url': videoElm.getVideoUrl(),
            'video_provider': 'HTML5',
            'video_duration': videoElm.getDuration(),
            'video_current_time': (videoElm.getCurrentTime()) ? (videoElm.getCurrentTime()).toFixed(2) : '',
            'video_percent': videoElm.percentage,
            'visible': true // true, false
        });
    },

    podcastProgress: (podcastElm) => {
        let eventName = ''
        if(podcastElm.percentage === 0){
            eventName = 'podcast_start'
        }else if(podcastElm.percentage === 100){
            eventName = 'podcast_complete'
        }else{
            eventName = 'podcast_progress'
        }
        GTMEvents.pushToDataLayer(eventName, {
            'podcast_title': podcastElm?.title,
            'podcast_url': podcastElm?.currentSrc,
            'podcast_provider': 'HTML5',
            'podcast_duration': podcastElm?.duration,
            'podcast_current_time': +(podcastElm?.currentTime)?.toFixed(2),
            'podcast_percent': podcastElm?.percentage,
        });
    },


    navigation: (navItem) => {
        let eventName = 'navigation'
        let baseUrl = window.location.origin
        baseUrl = baseUrl?.replace(/(^\w+:|^)\/\//, '');
        let eventData = {
            'link_text': navItem.label, 
            'link_url': `${baseUrl}${navItem.route}`, 
        }
        GTMEvents.pushToDataLayer(eventName, eventData);
    },

    navigationUtilityBar: (utilityText,action) => {
        let eventName = 'utility_bar'
        let baseUrl = window.location.href
        baseUrl = baseUrl?.replace(/(^\w+:|^)\/\//, '');
        let eventData = {
            'link_text': utilityText, 
            'link_url': baseUrl, 
            'user_action': action
        }
        //console.log("eventData",eventName,eventData)
        GTMEvents.pushToDataLayer(eventName, eventData);
    },

    scrollDepth: (scrollPercent) => {
        let eventName = 'scroll'
        let eventData = {
            'percent_scrolled': scrollPercent, 
        }
        //console.log("eventData",eventData)
        GTMEvents.pushToDataLayer(eventName, eventData);
    },

    outboundClick: (e) => {
        let eventName = 'outbound_click'
        let target =  e?.currentTarget
        const url = new URL(target.href);
        const domain = url.hostname;
        let eventData = {
            'link_text': target?.textContent,
            'link_url': target?.href,
            'link_domain': domain,
            'link_classes': target.className,
            'link_id': target.id,
            'outbound': true 
        }
        // console.log("eventData",eventName,eventData)
        GTMEvents.pushToDataLayer(eventName, eventData);
    },

    ctaClick: (linkText,pathName,actionName) => {
        let eventName = 'cta'
        let baseUrl = window.location.origin
        baseUrl = baseUrl?.replace(/(^\w+:|^)\/\//, '');
        let eventData = {
            'link_text': linkText,
            'link_url': `${baseUrl}${pathName}`,
            'user_action': actionName 
        }
        //console.log("eventData",eventName,eventData,)
        GTMEvents.pushToDataLayer(eventName, eventData);
    },

    exportFile: (exportElm) => {
        let eventName = 'file_download'
        const fileUrl = exportElm.href;
        const url = new URL(fileUrl);
        const domain = url?.origin?.replace(/(^\w+:|^)\/\//, '');
        let fileName = exportElm.getAttribute('download')
        const fileExt = fileName.split('.').pop();
    
        const eventData = {
          file_name: fileName,
          file_extension: fileExt,
          link_text: exportElm?.textContent,
          link_url: fileUrl.replace('blob:', ''),
          link_domain: domain,
          link_classes: exportElm?.className,
          link_id: exportElm?.id || '',
        };
        //console.log("eventData",eventName,eventData)
        GTMEvents.pushToDataLayer(eventName, eventData);
    },



};

export default GTMEvents
