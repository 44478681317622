import { useState} from "react";
import { useNavigate, Navigate, } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import GTMEvents from '../utils/GTMEvents';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      Copyright ©{new Date().getFullYear()}{" "}
      <Link color="inherit" href="https://www.lillyindia.co.in/">
        Eli Lilly and Company.
      </Link>{" "}
      All rights reserved.
    </Typography>
  );
}
const defaultTheme = createTheme();

export default function SignIn({ handleLogin, isSignedIn, validations, setValidations }) {
  const history = useNavigate();
  const [formInteracted, setFormInteracted] = useState(false);

  const handleFormInteraction = (event) => {
    if (!formInteracted) {
      setFormInteracted(true);
      GTMEvents.formInteraction(event,'Sign In')
    }
  };
  

  if (isSignedIn) {
    return <Navigate to="/home" replace />;
  } else {
    return (
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: '100vh' }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            // sx={{
              // backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
              // backgroundRepeat: 'no-repeat',
              // backgroundColor: (t) =>
                // t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              // backgroundSize: 'cover',
              // backgroundPosition: 'center',
            // }}
          />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign In
              </Typography>
              <Box component="form" noValidate onSubmit={handleLogin} sx={{ mt: 1 }} id="sign-in-form">
                {validations.isError ?
                  <Alert severity="error" sx={{ mt: 2, textTransform: 'capitalize', width: '100%' }}>
                    {validations.message}
                  </Alert>
                : null}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onFocus={handleFormInteraction}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onFocus={handleFormInteraction}
                  autoComplete="current-password"
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item>
                    <Link variant="body2" onClick={() => {
                      setValidations({ isError: false, message: "" })
                      history("/register")
                    }}>
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}
