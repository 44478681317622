export const USERS_DATA = [
    {email : 'michael.lawson@reqres.in', octa_id :  '00test45rt2YKVAAwe333'},
    {email : 'lindsay.ferguson@reqres.in', octa_id: '00test89vb2BNCXZdh666'},
    {email : 'tobias.funke@reqres.in', octa_id : '00test90NM4DJCNXjs777'},
    {email : 'byron.fields@reqres.in', octa_id : '00test21bn4fDGBjg999'},
    {email : 'george.edwards@reqres.in', octa_id : '00test17xs7ZUQWWdgz555'},
    {email : 'rachel.howell@reqres.in', octa_id : '00test63yv2EGVAAfg111'},
    {email : "george.bluth@reqres.in" , octa_id : '00test68at3LMXBZsa222'},
    {email : "janet.weaver@reqres.in" , octa_id : '00test78mb2XEABDpj444'},
    {email : "emma.wong@reqres.in" ,    octa_id : '00test99tu0JGCDKol888'},
    {email : "eve.holt@reqres.in" ,     octa_id : '00test67sx1BQPDFpc666'},
    {email : "charles.morris@reqres.in",octa_id : '00test94pv2TDSKIgl555'},
    {email : "tracey.ramos@reqres.in" , octa_id : '00test83op3YKLBBuw333'},
]




