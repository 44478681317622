import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';

import GTMEvents from '../utils/GTMEvents';

function ToggleColorMode({ mode, toggleColorMode }) {

  const handleToggleChange = ()=>{
    let toggleMode = (mode === 'dark') ? 'light': 'dark'
    GTMEvents.navigationUtilityBar('Toogle Theme',`toggle ${toggleMode}`)
    toggleColorMode()
  }
  return (
    <Box sx={{ maxWidth: '32px' }}>
      <Button
        variant="text"
        onClick={handleToggleChange}
        size="small"
        aria-label="button to toggle theme"
        sx={{ minWidth: '32px', height: '32px', p: '4px', color: mode === "dark" ? "#000000" : "#FFFFFF" }}
      >
        {mode === 'dark' ? (
          <ModeNightRoundedIcon fontSize="small" />
        ) : (
          <WbSunnyRoundedIcon fontSize="small" />
        )}
      </Button>
    </Box>
  );
}

ToggleColorMode.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default ToggleColorMode;
