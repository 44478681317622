import * as React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CSVLink } from 'react-csv';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import GetAppIcon from '@mui/icons-material/GetApp';
import { Typography } from "@mui/material";
import GTMEvents from '../utils/GTMEvents';

export default function Users() {
  const history = useNavigate();

  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    axios
      .get("https://reqres.in/api/users?page=2")
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleCreateUser = (e) => {
    GTMEvents.ctaClick(e?.target?.textContent,'/users/create','create')
    history(`/users/create`)
  };

  const handleEdit = (e,userId) => {
    GTMEvents.ctaClick('Edit User',`/users/edit/${userId}`,'edit')
    history(`/users/edit/${userId}`)
  };

  const handleDelete = (e,userId) => {
    GTMEvents.ctaClick('Delete User','/home','delete')
    axios
      .delete(`https://reqres.in/api/users/${userId}`)
      .then((res) => {
        const remainingUsers = users.filter((user) => user.id !== userId);
        setUsers(remainingUsers);
      })
      .catch((err) => console.log(err));
  };

  const handleExport = (event) => {
    const target = event.target.closest('a');
    GTMEvents.exportFile(target)
  };

  return (
    <Box>
      <Grid sx={{ m: 2 }}>
        {/* <Box display="flex" justifyContent="space-between" alignItems="center">
          <Grid>
            <Typography variant="h6">Users - {users.length}</Typography>
          </Grid>
          <Grid>
            <Button onClick={handleCreateUser}>
              Create User
            </Button>
          </Grid>
        </Box> */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Grid>
            <Typography variant="h6">Users - {users.length}</Typography>
          </Grid>
          <Grid display="flex">
            <CSVLink data={users} id="user-data-export" filename="user-data.csv" style={{ textDecoration: 'none' }}>
              <Button onClick={handleExport} startIcon={<GetAppIcon />}>
                Export
              </Button>
            </CSVLink>
            <Button onClick={handleCreateUser}>
              Create User
            </Button>
          </Grid>
        </Box>
      </Grid>
      <Grid sx={{ m: 2 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ fontWeight: "bold" }}>User ID</TableCell>
                <TableCell align="left" sx={{ fontWeight: "bold" }}>First Name</TableCell>
                <TableCell align="left" sx={{ fontWeight: "bold" }}>Last Name</TableCell>
                <TableCell align="left" sx={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow
                  key={user.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{user.id}</TableCell>
                  <TableCell align="left">{user.first_name}</TableCell>
                  <TableCell align="left">{user.last_name}</TableCell>
                  <TableCell align="left">{user.email}</TableCell>
                  <TableCell align="right">
                    <EditIcon
                      sx={{ mr: 2 }}
                      onClick={(e) => handleEdit(e,user.id)}
                    />
                    <DeleteForeverIcon onClick={(e) => handleDelete(e,user.id)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}
