import React,{useRef } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import GTMEvents from '../utils/GTMEvents';

export default function AboutUs() {
  const [selectedVideo, setSelectedVideo] = React.useState('');
  const videoRef = useRef(null);

  React.useEffect(() => {
    setSelectedVideo('https://www.youtube.com/watch?v=1OKT-Jik5Mo&t=1s');
  }, []);

  const handleProgress = ({ played }) => {
    const videoElm = videoRef.current.getInternalPlayer();
    const percentagePlayed = Math.min(played * 100, 100); 
   
    const thresholds = {
      0: 'zeroPercent',
      10: 'tenPercent',
      25: 'twentyFivePercent',
      50: 'fiftyPercent',
      75: 'seventyFivePercent',
      100: 'hundredPercentage'
    };

    // Reset previous milestone flags
    if(videoElm.url !== selectedVideo){
      for (const label of Object.values(thresholds)) {
        videoElm[label] = false;
      }
    }

    for (const [threshold, label] of Object.entries(thresholds)) {
      if (percentagePlayed >= threshold && !videoElm[label]) {
        videoElm[label] = true; // Mark this milestone as tracked
        videoElm.percentage = +threshold
        videoElm.url = selectedVideo
        console.log("============fires=======",threshold, label)
        //GTMEvents.videoProgress(videoElm)
      }
    }
  };

  return (
    <Grid container xs={12} sx={{ p: { xs: 0, md: 4 }, display: "flex", flexDirection: { xs: "column", sm: "row", md: "row", xl: "row" } }}>
      <Grid item xs={12} md={6} xl={6} sx={{ pr: { md: 4, }, m: { xs: 2, md: 0, xl: 0 }}}>
        <Typography variant='h4' sx={{ fontWeight: 600, color: "#d52b1e", mb: 2 }}>About Lilly</Typography>
        <Typography sx={{ fontSize: 32, mb: 2 }}>
          We’re a medicine company turning science into healing to make life better for people around the world.          
        </Typography>
        <Typography sx={{ fontSize: 16, mb: 2 }}>
          Harnessing the power of biotechnology, chemistry and genetic medicine, our scientists are urgently advancing science to solve some of the world's most significant health challenges. It all started nearly 150 years ago with a clear vision from founder Colonel Eli Lilly: "Take what you find here and make it better and better." 
        </Typography>
        <Typography sx={{ fontSize: 16,'& a': {fontWeight:600,color: "#d52b1e"} }}>
          But we’re not only dedicated to making medicines. We go beyond medicine to deliver innovative {' '} 
          <Link
            to="https://www.lilly.com/clinical-research/clinical-trials"
            target="_blank"
            onClick={(e) => GTMEvents.outboundClick(e)}
          >
            clinical trials
          </Link>
          {' '} that reflect the diversity of our world and work to ensure our medicines are accessible and {' '}
          <Link 
            to="https://insulinaffordability.lilly.com/"
            target="_blank"
            onClick={(e) => GTMEvents.outboundClick(e)}
          >
            affordable
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} xl={6} sx={{ pt: { xs: 4, sm: 4, md: 0 }, mb: { xs: 4 }}}>
        <ReactPlayer 
          url= {selectedVideo}
          ref={videoRef}
          onProgress={handleProgress}
          width="100%"
          height="100%"
          playing={false}
          loop={false}
          controls={true}
          volume={0.5}
          muted={false}
          playbackRate={1}
          style={{}}
          progressInterval={1000}
          playsinline={false}
          pip={false}
          stopOnUnmount={true}
          fallback={null}
          wrapper={'div'}
          playIcon
          previewTabIndex={0}
          config={{}}
        />
      </Grid>
    </Grid>
  )
}
